<template>
  <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.793 1.1c.392-1.21 2.102-1.21 2.495 0l1.991 6.13c.176.54.68.906 1.248.906h6.446c1.27 0 1.799 1.626.77 2.373l-5.214 3.789c-.46.334-.652.926-.477 1.466l1.992 6.13c.393 1.21-.99 2.214-2.018 1.467l-5.215-3.789a1.312 1.312 0 0 0-1.542 0l-5.215 3.79c-1.028.746-2.41-.259-2.018-1.467l1.992-6.13a1.312 1.312 0 0 0-.477-1.467l-5.215-3.79c-1.028-.746-.5-2.372.771-2.372h6.446c.568 0 1.072-.366 1.248-.906l1.992-6.13Z" fill="url(#reviewStartIconSvgLinearGradient1)" />
    <defs>
      <linearGradient
        id="reviewStartIconSvgLinearGradient1"
        x1="-24.725"
        y1="22.239"
        x2="13.849"
        y2="55.68"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F8B700" />
        <stop offset=".51" stop-color="#F87900" />
        <stop offset="1" stop-color="#AE35DD" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
}
</script>
