<template lang="pug">
.review-modal(
  :class="{'review-modal_active': review}"
)
  .review-modal__window(
    v-if="review"
  )
    a.review-modal__close(
      @click="close"
    )
      CloseIcon(
        :size="18"
      )
    SingleReviewCard(
      :full="true"
      :readMore="false"
      :data="review"
    )
</template>

<script>
import CloseIcon from '@/components/icons/default/CloseNarrow'
import SingleReviewCard from '@/components/pages/switch-to-rss/reviews/single'

export default {
  components: {
    CloseIcon,
    SingleReviewCard
  },

  props: {
    review: {
      type: Object,
      default: null
    }
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.review-modal {
  z-index: 1000;
  position: relative;

  &_active {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding: $gap;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .7);
  }

  &__window {
    position: relative;
  }

  &__close {
    top: $gap;
    right: $gap;
    position: absolute;
  }
}
</style>
