<template>
  <svg
    :width="size"
    :height="size"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.889 4.889L19.11 19.11M19.111 4.889L4.89 19.11"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="square"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    size: {
      type: Number,
      default: 24
    }
  }
}
</script>
