<template lang="pug">
section.slider.swiper(
  :class="sectionClasses"
)
  client-only
    .slider__wrapper.inner-1080
      h4.slider__title {{ $t('pages.ssr_switch_to_rss.reviews.title') }}
      .slider__main
        .swiper-container(
          :class="swiperClasses"
        )
          .swiper-wrapper
            .swiper-slide(
              v-for="(item, index) in items"
              :key="'slide-' + index"
            )
              SingleReview(
                :data="item"
                :readMore="readMore"
                @open="openTheReview"
              )

        // Next/Previous slide
        .slider__arrows
          .swiper-button.swiper-button-prev(
            @click="goToPrevSlide"
          )
            Arrow
          .swiper-button.swiper-button-next(
            @click="goToNextSlide"
          )
            Arrow

      // Pagination for Swiper slider
      .swiper-pagination(
        v-if="dots"
      )
        .swiper-pagination__element(
          v-for="(item, index) in paginationCount"
          :class="{'swiper-pagination__element_active': index === activeSlide}"
          @click="goToSlide(index)"
        )

    .slider__link-wrapper
      Link.slider__show-all.link-button-arrow-animation(
        target="_blank"
        href="https://www.reviews.io/company-reviews/store/rss-com"
        is-unstyled
      )
        template {{ $t('pages.ssr_landing.reviews.show_all') }}
        .link-button-arrow-animation__i
          SmallArrowIcon

    ReviewModal(
      :review="activeReview"
      @close="activeReview = null"
    )
</template>

<script>
import { Swiper } from 'swiper'
import 'swiper/swiper-bundle.min.css'

import Arrow from './Arrow'
import SingleReview from './single'

import Link from '@/components/common/Link/Link'
import ReviewModal from '@/components/modals/review/index'
import SmallArrowIcon from '@/components/icons/default/ArrowSmall'

export default {
  components: {
    Arrow,
    SingleReview,
    ReviewModal,
    SmallArrowIcon,
    Link
  },

  props: {
    id: {
      type: String,
      default: ''
    },

    light: {
      type: Boolean,
      default: false
    },

    slidesPerGroup: {
      type: Number,
      default: 4
    },

    episodes: {
      type: Boolean,
      default: false
    },

    neatForMobile: {
      type: Boolean,
      default: false
    },

    dots: {
      type: Boolean,
      default: true
    },

    readMore: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      swiper: null,
      activeReview: null,

      items: [
        {
          id: 1,
          img: '/images/reviews/mika_stambaugh.jpg',
          name: 'Mika Stambaugh',
          podcast: this.$t('pages.ssr_switch_to_rss.reviews.mika_stambaugh.podcast'),
          text: this.$t('pages.ssr_switch_to_rss.reviews.mika_stambaugh.text')
        },
        {
          id: 2,
          img: '/images/reviews/mark_vena.jpg',
          name: 'Mark Vena',
          podcast: this.$t('pages.ssr_switch_to_rss.reviews.mark_vena.podcast'),
          text: this.$t('pages.ssr_switch_to_rss.reviews.mark_vena.text')
        },
        {
          id: 3,
          img: '/images/reviews/jamie_birch.jpg',
          name: 'Jamie Birch',
          podcast: this.$t('pages.ssr_switch_to_rss.reviews.jamie_birch.podcast'),
          text: this.$t('pages.ssr_switch_to_rss.reviews.jamie_birch.text')
        },
        {
          id: 4,
          img: '/images/reviews/joseph_a_vore.jpg',
          name: 'Joseph A. Vore',
          podcast: this.$t('pages.ssr_switch_to_rss.reviews.joseph_a_vore.podcast'),
          text: this.$t('pages.ssr_switch_to_rss.reviews.joseph_a_vore.text')
        },
        {
          id: 5,
          img: '/images/reviews/ryan_cunningham.jpg',
          name: 'Ryan Cunningham',
          podcast: this.$t('pages.ssr_switch_to_rss.reviews.ryan_cunningham.podcast'),
          text: this.$t('pages.ssr_switch_to_rss.reviews.ryan_cunningham.text')
        },
        {
          id: 6,
          img: '/images/reviews/na_1.jpg',
          name: 'The Art of Conversations team',
          podcast: this.$t('pages.ssr_switch_to_rss.reviews.na_1.podcast'),
          text: this.$t('pages.ssr_switch_to_rss.reviews.na_1.text')
        },
        {
          id: 7,
          img: '/images/reviews/gabe_veenendaal.jpg',
          name: 'Gabe Veenendaal',
          podcast: this.$t('pages.ssr_switch_to_rss.reviews.gabe_veenendaal.podcast'),
          text: this.$t('pages.ssr_switch_to_rss.reviews.gabe_veenendaal.text')
        },
        {
          id: 8,
          img: '/images/reviews/trevor_bills.jpg',
          name: 'Trevor Bills',
          podcast: this.$t('pages.ssr_switch_to_rss.reviews.trevor_bills.podcast'),
          text: this.$t('pages.ssr_switch_to_rss.reviews.trevor_bills.text')
        },
        {
          id: 9,
          img: '/images/reviews/drake_box.jpg',
          name: 'Drake Box',
          podcast: this.$t('pages.ssr_switch_to_rss.reviews.drake_box.podcast'),
          text: this.$t('pages.ssr_switch_to_rss.reviews.drake_box.text')
        }
      ]
    }
  },

  computed: {
    sectionClasses () {
      return {
        swiper_light: this.light,
        swiper_invisible: this.isInvisible,
        swiper_loading: !this.swiper
      }
    },

    itemsCount () {
      return this.items.length
    },

    paginationCount () {
      return Math.ceil(this.itemsCount / this.activeCountOfSlidesPerSlide)
    },

    activeSlide () {
      if (!this.swiper) {
        return -1
      }
      return Math.ceil(this.swiper.activeIndex / this.swiper.params.slidesPerGroup)
    },

    swiperClasses () {
      return ['swiper-container_' + this.id]
    },

    isInvisible () {
      return this.itemsCount && this.itemsCount <= this.activeCountOfSlidesPerSlide
    },

    swiperOptions () {
      return {
        slidesPerGroup: 1,
        slidesPerView: 1,
        spaceBetween: 24,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        renderBullet: (index, className) => {
          return '<span class="' + className + '">' + (index + 1) + '</span>'
        },
        breakpoints: {
          480: {
            slidesPerGroup: 2,
            slidesPerView: 2
          },
          640: {
            slidesPerGroup: this.slidesPerGroup,
            slidesPerView: this.slidesPerGroup
          }
        }
      }
    },

    activeCountOfSlidesPerSlide () {
      if (!this.swiper) {
        return 1
      }
      const breakpoint = parseInt(this.swiper.currentBreakpoint)

      if (breakpoint === 480) {
        return 2
      }

      if (breakpoint === 640) {
        return 4
      }

      return 1
    }
  },

  mounted () {
    setTimeout(() => {
      this.swiper = new Swiper('.swiper-container_' + this.id, this.swiperOptions)
      this.$emit('loaded', true)
    }, 300)
  },

  methods: {
    goToNextSlide () {
      this.swiper.slideNext()
    },

    goToPrevSlide () {
      this.swiper.slidePrev()
    },

    goToSlide (index) {
      this.swiper.slideTo(index * this.activeCountOfSlidesPerSlide)
    },

    openTheReview (review) {
      this.activeReview = review
    }
  }
}
</script>

<style lang="scss" scoped>
.slider {
  color: #fff;
  position: relative;

  &__title {
    margin: 0;
    width: 100%;
    color: #fff;
    font-size: 24px;
    text-align: left;
    font-weight: bold;
    margin-bottom: $gap * 4;

    @include display-less(tablet) {
      margin-bottom: $gap;
    }
  }

  &__main {
    width: 100%;
    position: relative;
  }

  &__wrapper {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
  }

  /deep/ .slider__wrapper {
    min-width: 280px - $gap - $gap;
    .slide__card.card {
      box-shadow: none;
    }
  }

  @include display-less(desktop) {
    padding-bottom: $gap * 2;
  }

  &__link-wrapper {
    width: 100%;
    display: flex;
    margin-top: $gap * 1.5;
    justify-content: center;
  }

  &__show-all {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    align-items: center;
    display: inline-flex;
    text-decoration: none;
  }

  &__arrows {
    @media (max-width: 420px) {
      display: flex;
      position: relative;
      grid-gap: $gap * 0.5;
      justify-content: center;
    }
  }
}

.swiper {
  margin-bottom: 80px;
  padding-bottom: $gap * 3;

  @include display-less(tablet) {
    margin-bottom: 66px;
  }

  &-container {
    width: 100%;
    flex-grow: 0;
    min-width: 100%;
    max-width: 100%;
  }

  &-button {
    width: 40px;
    height: 40px;
    border-radius: 25px;
    box-sizing: border-box;
    transition: all .2s ease;
    background: rgba(0, 0, 0, 0.24);
    border: 1px solid rgba(241, 239, 255, 0.3);

    &:hover {
      background: rgba(0, 0, 0, 0.34);
      border-color: rgba(241, 239, 255, 0.6);
    }

    &:after {
      display: none;
    }

    &-prev {
      left: -$gap * 3;
    }

    &-next {
      right: -$gap * 3;
      transform: rotate(180deg);
    }

    @include display-less(big) {
      top: auto;
      width: 32px;
      height: 32px;
      bottom: -45px;

      &-prev {
        left: $gap;
      }

      &-next {
        right: $gap;
      }
    }

    @media (max-width: 420px) {
      bottom: auto;
      margin-top: $gap;
      position: static;
    }
  }

  &-pagination {
    width: 100%;
    display: flex;
    position: relative;
    margin-top: $gap * 1.5;
    justify-content: center;
    pointer-events: none;

    &__element {
      width: 24px;
      height: 12px;
      cursor: pointer;
      border-radius: 3px;
      box-sizing: border-box;
      margin-right: $gap / 2;
      transition: all .2s ease;
      background: rgba(0, 0, 0, 0.24);
      border: 1px solid rgba(241, 239, 255, 0.3);
      pointer-events: auto;

      &:last-child {
        margin-right: 0;
      }

      &:not(.swiper-pagination__element_active) {
        &:hover {
          background: rgba(241, 239, 255, 0.3);
        }
      }

      &_active {
        border-color: #fff;
        background-color: #fff;
      }
    }
  }

  // Styles of slider
  &_light {
    .swiper-button {
      background-color: none;
      background: linear-gradient(130.92deg, #F8B700 -0.63%, #F87900 58%, #AE35DD 114.33%);
    }

    .swiper-pagination {
      &__element {
        border: 1px solid #F87900;
        background: rgba(248, 121, 0, 0.1);

        &:not(.swiper-pagination__element_active) {
          &:hover {
            background: rgba(248, 121, 0, 0.3);
          }
        }

        &_active {
          border: 1px solid #F87900;
          background: rgba(248, 121, 0, 0.8);
        }
      }

      @media (max-width: 480px) {
        display: none;
      }
    }
  }

  // Invisible
  &_invisible {
    padding-bottom: 0;

    .swiper {
      &-pagination, &-button {
        display: none!important;
      }
    }
  }

  // Loading
  &_loading {
    height: 200px;
    position: relative;

    .slider__wrapper {
      opacity: 0;
    }

    .slider__loader {
      /deep/ .loading-background {
        opacity: 0;
      }
    }
  }
}
</style>
